@import './shared/variables.scss';

* {
  box-sizing: border-box;
}

header {
  font-family: 'Avenir LT Pro';
  text-align: center;
  font-size: 32px;
  color: $main-text-color;
  padding: 26px;
  p {
    margin: 0;
    &.main-title {
      font-family: 'Avenir LT Pro 65';
    }
  }
}

.content {
  font-family: 'Avenir LT Pro';
  font-size: 18px;
  color: $main-text-color;
  display: flex;
  max-width: 980px;
  margin: 0 auto;
}

.left-content {
  width: 50%;
  padding: 26px;
}

.right-content {
  width: 50%;
  padding: 26px;
  padding-left: 14px;
  height: 100%;
  img {
    width: 100%;
    border-bottom-right-radius: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .left-content {
    width: 100%;
    flex-grow: 1;
    padding: 18px;
  }
  .right-content {
    display: none;
  }
  header {
    padding-top: 0px;
  }
}

@import '../shared/variables.scss';

.form-container {
  display: flex;
  flex-direction: column;
  .form-description {
    margin-top: 0;
    font-size: 20px;
    line-height: 26px;
  }
  input {
    margin: 6px 0px;
    padding: 8px;
    font-family: 'Avenir LT Pro';
    color: $main-text-color;
    font-size: 16px;
    &.invalid {
      border-color: red;
      border-width: 2px;
    }
  }
  .autocomplete-container {
    margin-top: 6px;
    & > div {
      font-size: 16px;
    }
  }
  .terms-and-conditions {
    display: flex;
    align-items: center;
    font-size: 14px;
    &.invalid {
      .checkbox {
        border-color: red;
      }
    }
    a {
      color: $main-text-color;
    }
    .checkbox {
      margin-right: 24px;
      margin-bottom: 10px;
    }
    // input {
    //   margin-right: 24px;
    //   min-width: 24px;
    //   min-height: 24px;
    //   &:checked {
    //     background-color: $main-color;
    //   }
    // }
  }
  .form-errors {
    p {
      color: red;
      margin-bottom: 0;
      font-family: 'Avenir LT Pro';
    }
  }
  .form-actions {
    display: flex;
    align-items: center;
    margin-top: 24px;
    button {
      background-color: $main-color;
      border: none;
      color: white;
      padding: 16px 42px;
      border-radius: 48px;
      max-width: fit-content;
      font-size: 18px;
      font-family: 'Avenir LT Pro 65';
      cursor: pointer;
      letter-spacing: 0.5px;
      margin-left: 12px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .form-actions {
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin-top: 12px;
    }
  }
}

.form-loading {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 44px;
    height: 44px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $main-color;
    border-color: $main-color transparent $main-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@font-face {
  font-family: 'Avenir LT Pro 65';
  src: url('./resources/fonts/AvenirLTPro-Black.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./resources/fonts/AvenirLTPro-Roman.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
